<template>
  <div>
    <a href="#" v-if="showRenewalButton" class="btn btn-danger" @click.prevent="routeDuePayment">
      {{ i18n['profile-dues-info'].tcRenewMembership }}
    </a>
    <a href="#" v-if="showPastDueButton" class="btn btn-danger" @click.prevent="routeDuePayment">
      {{ i18n['profile-dues-info'].tcRenewMembershipPastDue }}
    </a>
    <a href="#" v-if="showPastDueInstallButton" class="btn btn-danger" @click.prevent="routeInstallmentDuePayment">
      {{ i18n['profile-dues-info'].tcRenewMembershipInstallments }}
    </a>
  </div>
</template>

<script>
/* eslint-disable */
import constantData from '@/json/data.json'
import { mapGetters, mapActions } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'profile-dues-info-user-profile',
  mixins: [translationMixin],
  props: {
    profile: {
      type: Object,
      default: () => {
        return {
          join_date: '',
          paid_date: '',
          paid_flag: false,
          member_type_key: null,
        }
      },
    },
  },
  data() {
    return {
      i18n: {
        'profile-dues-info': {
          tcRenewMembership: 'Renew Membership',
        },
      },
    }
  },
  async created() {
    this.i18n = await this.getComponentTranslations('profile-dues-info')
  },
  watch: {
    async prefCulture(newValue, oldValue) {
      this.i18n = await this.getComponentTranslations('profile-dues-info')
    },
  },
  methods: {
    ...mapActions({
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadMembersByCamp: 'membership/loadMembersByCamp',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey'
    }),
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
    async routeDuePayment() {
      await this.setSelectedIndividualKey(this.userId)
      await this.loadIndividualProfile()
      await this.loadMembersByCamp(this.individualProfile.camp_key)
      this.$router.push({ name: 'member-dues', params: { m: 'link' } })
    },
    async routeInstallmentDuePayment() {
      await this.setSelectedIndividualKey(this.userId)
      await this.loadIndividualProfile()
      await this.loadMembersByCamp(this.individualProfile.camp_key)
      this.$router.push('/programs/mb/member-payment-center/');
    },
  },
  computed: {
    ...mapGetters({
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userLogin: 'user/userLogin'
    }),
    showRenewalButton() {
      return (
        !!constantData.USA_membership_types[this.profile.member_type] &&
        this.isUSMember &&
        this.userLogin.usa_dues_season === 1 && !this.profile.paid_flag)
    },
    showPastDueButton() {
      return (
        !!constantData.USA_membership_types[this.profile.member_type] &&
        this.isUSMember &&
        this.userLogin.usa_dues_season === 0 && this.userLogin.mbr_pending_drop_date !== null && !this.userLogin.mbr_installpay && !this.profile.paid_flag)
    },
    showPastDueInstallButton() {
      return (
        !!constantData.USA_membership_types[this.profile.member_type] &&
        this.isUSMember && this.userLogin.installments_season &&
        this.userLogin.usa_dues_season === 0 && this.userLogin.mbr_pending_drop_date !== null && this.userLogin.mbr_installpay && !this.profile.paid_flag)
    },
    isUSMember() {
      return this.userLogin.membertype.toLowerCase() === "usa"
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>
