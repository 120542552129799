<template>
  <div
    class="user col-5 col ml-auto"
    :class="{ hovered: showProfile }"
    @mouseleave="closeProfile"
    v-if="translations.components"
  >
    <div class="user-profile__inner">
      <div class="user-profile__overview">
        <select v-if="select" v-model="id" @change="setUser(id)">
          <option v-for="(user, index) in options" :value="index" :key="index">
            {{ user.name }}
          </option>
        </select>
        <div class="user-profile__info">
          <h6 class="user-profile__name">{{ userName }}</h6>
          <div class="user-profile__meta">{{ userCampNumber }} | {{ userLocation }}</div>
          <div class="user-renewDues">
            <ProfileDuesInfo :profile="individualProfileHeader" :i18n="translations.components" />
          </div>
        </div>
        <div class="user-profile__photo">
          <Avatar v-if="avatarUrl === ''" :className="className"></Avatar>
          <img v-if="avatarUrl !== ''" :src="avatarUrl" alt="" />
        </div>
        <div class="profile-caret" @mouseenter="toggleProfile" @click="toggleProfile">
          <span></span>
        </div>
      </div>
      <div class="user-profile__extended">
        <div class="user-profile__links">
          <a href="#" class="btn btn-tertiary btn--logout" @click.prevent="logout">
            {{ translations.components['header-user'].tcLogout }}</a
          >
          <a href="#" class="btn btn-primary btn--edit" @click.prevent="handleEditClick">
            {{ translations.components['header-user'].tcView }}
          </a>
        </div>
        <div class="user-profile__hub">
          <div class="col">
            <div class="row flex-column align-items-end">
              <div class="user-profile__upcoming mt-1">
                <p>
                  <span class="label">{{ translations.components['header-user'].tcNextUpcomingEvent }}</span>
                  <a href="#" @click.prevent="handleNextUpcomingClick" class="btn btn-tertiary">
                    {{ translations.components['header-user'].tcView }}
                  </a>
                </p>
              </div>
              <div class="user-profile__anouncements mt-1">
                <p>
                  <span class="plus5">
                    <Announcement />
                  </span>
                  <span class="count" v-if="announcementCount > 0 || announcementCount === '99+'">
                    {{ announcementCount }}
                  </span>
                  <span class="label">{{ translations.components['header-user'].tcAnnouncements }}</span>
                  <a href="#" @click.prevent="handleAnnouncementViewClick" class="btn btn-tertiary">
                    {{ translations.components['header-user'].tcView }}
                  </a>
                </p>
              </div>
              <div class="user-profile__anouncements mt-1">
                <p>
                  <span class="label">
                    <span class="plus5">
                      <Globe />
                    </span>
                    <strong>{{ translations.components['header-user'].tcLanguage }}:&nbsp;</strong>
                    <span class="language">{{ translatedUserPreferredLanguage }}</span>
                  </span>
                  <a href="#" @click.prevent="handleLanguageEditClick()" class="btn btn-primary">
                    {{ translations.components['header-user'].tcEdit }}
                  </a>
                </p>
              </div>
              <div
                class="user-profile__anouncements mt-1"
                v-if="iCanSee(secured_controls.impersonation_button) || userImpersonation"
              >
                <p>
                  <span class="plus5">
                    <Impersonate />
                  </span>
                  <a href="#" @click.prevent="handleImpersonateClick()" class="btn btn-primary">{{
                    impersonateLabel
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Announcement from '@/assets/svgs/announcement.vue'
import Avatar from '@/assets/svgs/avatar.vue'
import Globe from '@/assets/svgs/globe.vue'
import Impersonate from '@/assets/svgs/impersonate.vue'
import NotificationsBell from '@/assets/svgs/notifications-bell.vue'
import { mapGetters, mapActions } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'
import { memberMessagePopupMixin } from '@/mixins/memberMessagePopupMixin'
import ProfileDuesInfo from '@/components/ProfileDuesInfoUserProfile'

export default {
  name: 'header-user',
  mixins: [translationMixin, memberMessagePopupMixin],
  data() {
    return {
      secured_controls: {
        impersonation_button: '72c9da1e-c221-48de-b788-c7969f4b1935'
      },
      className: 'cls-1',
      options: [],
      select: false,
      id: 'U41035',
      showProfile: false,
      translations: {
        commonText: {
          'church-languages': {
            tcChinese: 'Chinese',
            tcEnglish: 'English',
            tcFrench: 'French',
            tcGerman: 'German',
            tcJapanese: 'Japanese',
            tcKorean: 'Korean',
            tcOther: 'Other',
            tcRussian: 'Russian',
            tcSpanish: 'Spanish'
          }
        },
        common: {
          'church-languages': {
            'b468f566-42a9-4b07-a5a0-f2ac4afd1a36': 'Chinese',
            'c3964f7d-1307-48b9-9f60-dae9388b5478': 'English',
            'dfedf829-347b-4959-9d6b-a072529c4cc8': 'French',
            '1da6e0c6-a66d-4216-adc1-7816e0ab845c': 'German',
            '36c1a24f-21f3-43bb-97e3-630390b75d43': 'Japanese',
            'a1737579-fe30-40c2-ad3e-ff880ab79646': 'Korean',
            'ff8d6bae-8f65-4aaa-be74-506ffac2241a': 'Other',
            '5bee967a-1425-4d08-9ca2-bca08217b3b4': 'Russian',
            '8a2beb3f-a979-4314-9e5f-702c3e25c3bd': 'Spanish'
          }
        },
        components: {
          'header-user': {
            tcView: 'View',
            tcEdit: 'Edit',
            tcLogout: 'Logout',
            tcImpersonate: 'Impersonate',
            tcLanguage: 'Language',
            tcOk: 'Ok',
            tcCancel: 'Cancel',
            tcAnnouncements: 'Announcement(s)',
            tcEndImpersonation: 'End Impersonation',
            tcErrorImpersonating: 'Error Impersonating.',
            tcNewNotifications: 'New Notification(s)',
            tcNextUpcomingEvent: 'Next Upcoming Event',
            tcEnterNumberMemberForImpersonation: 'Enter member number for impersonation.'
          },
          'profile-dues-info': {
            tcNo: 'No',
            tcYes: 'Yes',
            tcMembershipRenewal: 'Membership Renewal',
            tcJoinDate: 'Join Date',
            tcMembershipRenewed: 'Membership Renewed',
            tcRenewMembership: 'Renew Membership',
            tcMembershipRenewedDate: 'Membership Renewed Date'
          },
          common: {
            'church-languages': {
              'b468f566-42a9-4b07-a5a0-f2ac4afd1a36': 'Chinese',
              'c3964f7d-1307-48b9-9f60-dae9388b5478': 'English',
              'dfedf829-347b-4959-9d6b-a072529c4cc8': 'French',
              '1da6e0c6-a66d-4216-adc1-7816e0ab845c': 'German',
              '36c1a24f-21f3-43bb-97e3-630390b75d43': 'Japanese',
              'a1737579-fe30-40c2-ad3e-ff880ab79646': 'Korean',
              'ff8d6bae-8f65-4aaa-be74-506ffac2241a': 'Other',
              '5bee967a-1425-4d08-9ca2-bca08217b3b4': 'Russian',
              '8a2beb3f-a979-4314-9e5f-702c3e25c3bd': 'Spanish'
            }
          }
        }
      },
      translatedUserPreferredLanguage: 'English',
      user_data: {
        id: '',
        name: '',
        location: '',
        camp_key: '',
        ind_key: '',
        lng_key: '',
        mnu_key: '',
        org_key: '',
        temp_org_key: '8325C7F3-173C-4588-AAEA-808256F853AC'
      }
    }
  },
  async created() {
    await this.page_load()
  },
  methods: {
    ...mapActions({
      clearAnnouncementsStore: 'announcements/clearAnnouncementsStore',
      clearOfficerToolbarStatesCamps: 'user/clearOfficerToolbarStatesCamps',
      getAllowedControls: 'user/getAllowedControls',
      getIndividualCommPreferences: 'membership/getIndividualCommPreferences',
      getUserImpersonation: 'user/getUserImpersonation',
      getUserImpersonationStop: 'user/getUserImpersonationStop',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadIndividualProfileHeader: 'membership/loadIndividualProfileHeader',
      loadMemberAvatar: 'membership/getMemberAvatar',
      loadMemberPopup: 'membership/loadMemberPopup',
      retrieveMenu: 'menu/setMenu',
      setAuthenticated: 'user/setAuthenticated',
      setHomeBannerWidgetData: 'home/setHomeBannerWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberPopupShown: 'user/setMemberPopupShown',
      setOfficerToolbarDefault: 'home/setOfficerToolbarDefault',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setUser: 'user/setUser',
      translateMenu: 'translation/fetchMenuTranslations',
      unsetStateUndist: 'campModule/unsetStateUndist',
      setGoBackBtn: 'stateGoalPlanner/setGoBackBtn',
      updatePreviousRouteForBackToStateBTn: 'stateGoalPlanner/updatePreviousRoute'
    }),
    async page_load() {
      try {
        await Promise.all([
          await this.loadIndividualProfileHeader(this.userId),
          this.getComponentTranslations('header-user', 'profile-dues-info'),
          this.getComponentTranslations('common.church-languages'),
          this.loadMemberAvatar(this.userId)
        ]).then(results => {
          this.updateStoredTranslations(results[1], results[2])
        })
      } catch (e) {
        console.error('Error in User.vue, page_load(), ', e)
      } finally {
      }
    },
    handleAnnouncementViewClick() {
      this.$router.push({ path: '/get-connected/stay-informed/announcements' })
    },
    handleNextUpcomingClick() {
      this.$router.push({ path: '/calendar/calendar-month' })
    },
    async handleEditClick() {
      this.toggleProfile()
      await this.setSelectedIndividualKey(this.userId)
      if (
        this.$route.path === '/programs/mb/member-profile' ||
        this.$route.path === '/programs/mb/member-profile/menu'
      ) {
        this.$root.$emit('member-profile-reload')
      } else {
        await this.$router.push({ name: 'user-profile' }).catch(e => {})
      }
    },
    async handleImpersonateClick() {
      let onHome = false
      this.updatePreviousRouteForBackToStateBTn(null)
      this.setGoBackBtn(false)
      if (this.$router.currentRoute.path === '/') {
        onHome = true
      }
      await this.setSearchTerm(null)
      if (!this.userImpersonation) {
        await this.impersonate()
      } else {
        await this.stopimpersonate()
      }
      this.toggleProfile()
      if (onHome) {
        this.$root.$emit('HomePageLoad', 'Emitted From User.vue')
      }
    },
    async handleLanguageEditClick() {
      try {
        this.setLoadingStatus(true)
        this.toggleProfile()
        this.setSelectedIndividualKey(this.userId)
        await this.loadIndividualProfile()
        await this.getIndividualCommPreferences(this.userSelectedIndividualKey)
        this.$router.push({ name: 'edit-preferred-language' })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    closeProfile() {
      this.showProfile = false
      this.className = 'cls-2'
    },
    toggleProfile() {
      this.showProfile = !this.showProfile
      this.className = 'cls-1'
    },
    async logout() {
      await this.setAuthenticated(false).then(() => {
        this.$router.push('/login').catch(errr => {})
      })
    },
    async impersonate() {
      let result = await this.$swal({
        icon: 'question',
        text: this.translations.components['header-user'].tcEnterNumberMemberForImpersonation,
        input: 'text',
        showCancelButton: true,
        confirmButtonText: this.translations.components['header-user'].tcOk,
        cancelButtonText: this.translations.components['header-user'].tcCancel
      })
      if (result.value && result.value !== '') {
        var payload = {
          memberno: result.value
        }
        await this.getUserImpersonation(payload).then(result => {
          if (result == true && this.userLogin.isAuthorized) {
            this.$router.push('/').catch(errr => {})
            this.impersonationTasks()
          } else {
            this.$swal({
              icon: 'error',
              text: this.translations.components['header-user'].tcErrorImpersonating,
              showCancelButton: false,
              confirmButtonText: this.translations.components['profile-contact-info'].tcOk
            }).then(result => {
              return
            })
          }
        })
      } else {
        return
      }
    },
    async stopimpersonate() {
      await this.getUserImpersonationStop()
        .then(async result => {
          if (result === true) {
            this.$router.push('/').catch(errr => {})
            await this.impersonationTasks()
          }
          return
        })
        .catch(error => {
          return
        })
    },
    async impersonationTasks() {
      await (async () => {
        this.user_data.location = this.userLogin.campname
        this.user_data.camp_key = this.userLogin.campkey
        this.user_data.state_key = this.userLogin.statekey
        this.user_data.ind_key = this.userLogin.indkey
        this.user_data.mnu_key = this.userLogin.indkey
        this.user_data.org_key = this.userLogin.campkey
        this.user_data.name = this.userLogin.name
        this.user_data.id = this.userLogin.campnumber
        this.user_data.lng_key = this.userLogin.languagekey
        this.unsetStateUndist()
        this.setUser(this.user_data)
        this.clearOfficerToolbarStatesCamps()
        this.setOfficerToolbarDefault({
          ind_key: this.userLogin.indkey,
          org_key: this.userLogin.campkey,
          lng_key: this.userLogin.languagekey
        })
        await this.getAllowedControls()
        await this.retrieveMenu(this.user_data.mnu_key)
        this.loadIndividualProfileHeader(this.userId)
        this.loadMemberAvatar(this.userId)
        this.setHomeBannerWidgetData()
        this.setMemberPopupShown(false)
      })()
    },
    /**
     * Updates displayed text for user's preferred language (translated) in the Header>User Profile Card
     */
    translateLanguageName() {
      if (!this.translations.commonText || !this.userPreferredLanguage) return false

      const translationLookup = this.convertValForTranslation(this.userPreferredLanguage)

      return (this.translatedUserPreferredLanguage = this.translations.commonText['church-languages'].hasOwnProperty(
        translationLookup
      )
        ? this.translations.commonText['church-languages'][translationLookup]
        : this.userPreferredLanguage)
    },
    /**
     * Updates the stored translations after the user's language preference has changed
     * @param {Object} resultsComponents results from the regular component call via this.getComponentTranslations()
     *   - IE, this.getComponentTranslations('back-jump', 'camp-select')
     * @param {Object} resultsCommon results from the regular common component call via this.getComponentTranslations()
     *   - IE, this.getComponentTranslations('common.church-languages', 'common.email-types')
     */
    updateStoredTranslations(resultsComponents, resultsCommon) {
      if (!resultsComponents || !resultsCommon) return false
      this.stripReadableText(resultsCommon, 'text')
      const translatedText = {
        ...resultsComponents,
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
      this.translateLanguageName()
    }
  },
  computed: {
    ...mapGetters({
      announcementCount: 'announcements/announcementCount',
      authenticated: 'user/authenticated',
      iCanSee: 'user/iCanSee',
      individualProfile: 'membership/individualProfile',
      individualProfileHeader: 'membership/individualProfileHeader',
      memberAvatar: 'membership/getAvatar',
      prefCulture: 'user/userPreferredCulture',
      userAvatar: 'user/userAvatar',
      userCampKey: 'user/userCampKey',
      userCampNumber: 'user/userCampNumber',
      userId: 'user/userId',
      userImpersonation: 'user/userImpersonation',
      userLocation: 'user/userLocation',
      userLogin: 'user/userLogin',
      userName: 'user/userName',
      userNotifications: 'user/userNotificationsCount',
      userPreferredLanguage: 'user/userPreferredLanguage',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey'
    }),
    impersonateLabel() {
      return this.userImpersonation
        ? this.translations.components['header-user'].tcEndImpersonation
        : this.translations.components['header-user'].tcImpersonate
    },
    avatarUrl() {
      return !!this.memberAvatar[0] && !!this.memberAvatar[0].iim_image_url_path
        ? this.memberAvatar[0].iim_image_url_path
        : ''
    }
  },
  watch: {
    async prefCulture() {
      await Promise.all([
        this.getComponentTranslations('header-user'),
        this.getComponentTranslations('common.church-languages')
      ]).then(results => {
        this.updateStoredTranslations(results[0], results[1])
      })
    }
  },
  components: {
    Announcement: Announcement,
    Avatar: Avatar,
    Globe: Globe,
    Impersonate: Impersonate,
    svgNotificationsBell: NotificationsBell,
    ProfileDuesInfo: ProfileDuesInfo
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

.plus5 {
  padding-right: 5px;
}

.user {
  color: #fff;

  @include breakpoint(sm) {
    max-width: 100%;
    width: 100%;
  }

  .user-renewDues {
    margin-top: 10px;
  }

  .user-profile {
    padding: 10px 15px;
  }

  .user-profile__inner {
    position: relative;
    transition: background-color 0.3s ease-in;
  }

  select {
    flex: 0 0 auto;
    width: 50px;
  }

  .user-profile__overview {
    display: flex;
    align-items: center;
    padding: 10px 30px 10px 25px;
  }

  .user-profile__info {
    flex: 1 1 auto;
    text-align: right;

    @include breakpoint(sm) {
      margin-left: 1rem;
      text-align: left;
    }
  }

  .user-profile__photo {
    padding-left: 10px;
    position: relative;

    img {
      border: 3px solid #fff;
      border-radius: 100%;
      height: 50px;
      padding: 2px;
      width: 50px;
    }
  }

  .profile-caret {
    padding: 10px;
    cursor: pointer;
    margin-left: 5px;

    span {
      display: block;
      height: 8px;
      width: 8px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #ffffff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .user-profile__name {
    margin-bottom: 5px;
    font-family: $open-sans;
    font-weight: 500;
    font-size: 19px;
    letter-spacing: 1.56px;
    line-height: 25px;

    @include breakpoint(sm) {
      color: #fff;
      font-size: 17px;
    }
  }

  .user-profile__meta {
    font-size: 15px;
    font-family: $open-sans;
    letter-spacing: 1.27px;
    line-height: 19px;

    @include breakpoint(sm) {
      color: #fff;
    }
  }

  .btn {
    font-size: 12px;
    padding: 4px 15px;
  }

  .user-profile__extended {
    background-color: #fff;
    color: #003946 !important;
    font-size: 12px;
    opacity: 0;
    position: absolute;
    top: auto;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    transition-property: margin-top, opacity, visibility;
    width: 100%;
    z-index: -1;

    .btn {
      min-width: 65px;
    }

    .count {
      color: #ff3d00;
      font-weight: 500;
      padding-right: 5px;
    }

    .label {
      color: #636363;
      padding-right: 12px;
    }

    .label strong,
    .label span {
      color: #003946 !important;
    }

    .language {
      color: #003946;
      font-weight: 500;
    }

    .flag {
      clip-path: circle(40%);
      float: left;
      margin-top: -6px;
      margin-right: -10px;
    }

    .user-profile__links {
      border-bottom: 2px solid #9f9f9f;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 15px;
      text-align: right;

      .btn--logout {
        margin-right: 12px;
      }
    }

    .user-profile__hub {
      padding: 15px 25px;
    }

    .user-profile__notifications {
      svg {
        padding-right: 10px;
        width: 26px;
      }

      .label {
        font-weight: 500;
        padding-right: 0;
      }
    }
  }
}

.user.hovered {
  .user-profile__inner {
    background-color: #fff;
  }

  .user-profile__photo {
    img {
      border-color: #003946;
    }
  }

  .profile-caret {
    span {
      border-color: #003946;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      margin-top: 4px;
    }
  }

  .user-profile__extended {
    background-color: #fff;
    display: block;
    height: auto;
    opacity: 1;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    transition-property: margin-top, opacity, visibility;
    z-index: 5;
  }

  .user-profile__name,
  .user-profile__meta,
  .label {
    color: #003946 !important;
  }

  .count {
    color: #ff3d00 !important;
    font-weight: 700;
  }
}

@media (max-width: 767px) {
  .user.hovered .user-profile__extended {
    top: calc(100% - 1px);
    z-index: 999;
  }

  .user .user-profile__extended .user-profile__hub {
    padding: 12px 20px;
    color: #003946 !important;
  }

  .user .user-profile__extended .user-profile__link {
    padding-left: 20px;
    padding-right: 20px;
  }

  .user .user-profile__overview {
    padding: 10px 0;
  }
}

@media (max-width: 350px) {
  .user .user-profile__info {
    margin-left: 10px;
  }

  #nav .premenu {
    padding: 0 !important;
  }

  .user .user-profile__name {
    letter-spacing: 0;
  }

  #nav .user {
    padding: 0;
  }

  .user .user-profile__extended .user-profile__hub {
    padding: 12px 10px;
  }

  .user .user-profile__extended .user-profile__link {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
